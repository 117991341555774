@keyframes overlayPopUp{
    0%{transform: scale(.6);}
    60%{transform: scale(1.02);}
}
.overlay{
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    width: 100dvw;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    pointer-events: none;
}
.darken{
    background-color: var(--c-overlay);
}
.cover{
    pointer-events: all;
}
.overlayWrap > *{
    box-shadow: #1010100C 0 0 0 .0625rem, 0 .25rem .9rem .5rem #00000033, 0 .25rem 1rem .75rem #00000008;
    border-radius: .375rem;
    padding: .8rem 1rem;
    background-color: var(--c-base);
    pointer-events: initial;
}
.animate > *{
    animation: overlayPopUp .2s ease;
}
.error{
    display: grid;
    place-items: center;
    text-align: center;
    color: var(--c-danger);
}