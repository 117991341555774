/*Webkit 滚动条*/
::-webkit-scrollbar{
    width: .48rem;
    height: .48rem;
}
@property --sbbc{
    syntax: '<color>';
    inherits: false;
}
::-webkit-scrollbar-thumb{
    --sbbc: var(--c-black-tra-3);
    background-color: var(--sbbc);
    border-radius: .75rem;
    border: .5rem solid transparent;
    box-shadow: inset .375rem 0 0 var(--c-grey-ll);
    transition: --sbbc .4s ease;
}
::-webkit-scrollbar-thumb:hover{
    --sbbc: var(--c-black-tra-5);
}
::-webkit-scrollbar-thumb:active{
    --sbbc: var(--c-black-tra-4);
}
::-webkit-scrollbar-track{
    background-color: transparent;
}
::-webkit-scrollbar-corner{
    height: 0;
    width: 0;
    background-color: transparent;
}
::-webkit-scrollbar-button{
    height: 0;
    width: 0;
    background-color: transparent;
}
/*Firefox 滚动条*/
*{
    scrollbar-color: var(--c-black-tra-3) transparent;
    scrollbar-gutter: auto;
    scrollbar-width: thin;
}