.window{
    position: fixed !important;
    z-index: 50;
    transition: background-color .35s cubic-bezier(0.8, 0.2, 0.5, 1);
    touch-action: none;
    /*???:note:NB:fuck:hack:important:fixed:
    这里不能写overflow:hidden，因其会在内部DOM超出边框盒的一瞬间尝试滚动内容（在内部DOM开始滚动之前），因此会让windowInner向上偏移！
    overflow:hidden：https://developer.mozilla.org/zh-CN/docs/Web/CSS/overflow#:~:text=%E5%86%85%E5%AE%B9%E5%8F%AF%E4%BB%A5%E4%BB%A5%E7%BC%96%E7%A8%8B%E7%9A%84%E6%96%B9%E5%BC%8F%E6%BB%9A%E5%8A%A8%EF%BC%88%E4%BE%8B%E5%A6%82%EF%BC%8C%E9%80%9A%E8%BF%87%E8%AE%BE%E7%BD%AE%20scrollLeft%20%E7%AD%89%E5%B1%9E%E6%80%A7%E7%9A%84%E5%80%BC%E6%88%96%20scrollTo()%20%E6%96%B9%E6%B3%95%EF%BC%89%2C%20%E5%9B%A0%E6%AD%A4%E8%AF%A5%E5%85%83%E7%B4%A0%E4%BB%8D%E7%84%B6%E6%98%AF%E4%B8%80%E4%B8%AA%E6%BB%9A%E5%8A%A8%E7%9A%84%E5%AE%B9%E5%99%A8%E3%80%82
    overflow:clip：https://developer.mozilla.org/zh-CN/docs/Web/CSS/overflow#:~:text=%E8%AF%A5%E7%9B%92%E5%AD%90%E4%B8%8D%E6%98%AF%E4%B8%80%E4%B8%AA%E6%BB%9A%E5%8A%A8%E7%9A%84%E5%AE%B9%E5%99%A8%EF%BC%8C%E5%B9%B6%E4%B8%94%E4%B8%8D%E4%BC%9A%E5%90%AF%E5%8A%A8%E6%96%B0%E7%9A%84%E6%A0%BC%E5%BC%8F%E5%8C%96%E4%B8%8A%E4%B8%8B%E6%96%87%E3%80%82
    */
    overflow: hidden; /*兼容*/
    overflow: clip;
    box-sizing: border-box;
}
/*Resizer*/
.window > div:last-child{
    transition: opacity .35s cubic-bezier(0.8, 0.2, 0.5, 1);
}
.windowUI{
    box-shadow: #1010100C 0 0 0 .0625rem, #00000040 0 .175rem .75rem .0625rem;
    border-radius: .3125rem;
    padding: .8rem 1rem;
    background-color: var(--c-base);
}
.windowHidden{
    display: none !important;
}
.hoverWindow{
    background-color: var(--c-link-tra);
}
/*Resizer*/
.hoverWindow > div:last-child{
    opacity: .45;
}
.windowInner{
    padding: 0;
    cursor: initial;
    overflow: overlay;
    height: 100%;
    width: 100%;
    /*touch-action: unset;*/
}
.attached_t{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.attached_b{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.attached_l{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.attached_r{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.error{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    text-align: center;
    color: var(--c-danger);
}