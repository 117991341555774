.button{
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: .92rem;
    color: var(--c-text-menu);
    transition: background-color .2s ease;
    width: 100%;
    height: 100%;
}
.button:active{
    transition: background-color .05s ease;
}
.button:disabled, .button:disabled:hover, .button:disabled:active{
    cursor: default;
    color: var(--c-grey-1);
}
.div{
    padding: 10%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}