.noselect{
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.reselect{
    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text;
}
.nodrag{
    -webkit-user-drag: none;
}