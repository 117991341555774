::selection{
    background-color: var(--c-selection);
}
::-moz-selection{
    background-color: var(--c-selection);
}
html{
    font-size: var(--fontsize);
}
*{
    outline: none;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
    transition: background-color .1s;
    line-height: normal;
}
/*正常化浏览器行为*/
*[contenteditable]:not([contenteditable="false"]){
    /*-moz-user-modify: read-write;
    -webkit-user-modify: read-write-plaintext-only;
    user-modify: read-write-plaintext-only;*/
}
*[contenteditable]:not([contenteditable="false"]) *{
    white-space: pre-wrap;
    word-wrap: normal;
    word-break: break-word;
    overflow-wrap: break-word;
}
#oinkedit{
    height: 100vh;/*兼容*/
    height: 100dvh;
    width: 100vw;/*兼容*/
    width: 100dvw;
    overflow-x: hidden;
    overflow-y: auto;/*兼容*/
    overflow-y: overlay;
    /*目前不计划使画布出现滚动条了*/
    overflow-y: hidden;
    display: flex;
    flex-flow: column nowrap;
}
.oe-launch-error{
    text-align: center;
    max-width: 50rem;
    border: solid .125rem red;
    border-radius: .5rem;
    padding: 1rem;
    box-shadow: 0 0 1.25rem 0 #90000046, inset 0 0 1rem .0625rem #2a000041;
}
noscript{
    display: grid;
    place-items: center;
}
button{
    appearance: auto;
    display: block;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    border: none;
    border-radius: 0;
}