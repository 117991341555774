@property --per{
    syntax: '<length>';
    inherits: false;
}
.li{
    --per: .2rem;
    font-size: .9rem;
    height: calc(100% - .1875rem);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    border-top-style: solid;
    border-top-width: .1875rem;
    /*var的保留值是给Firefox和Safari用的*/
    border-image: linear-gradient(to right,
        transparent var(--per, .25rem),
        var(--c-active) var(--per, .25rem),
        var(--c-active) calc(100% - var(--per, .25rem)),
        transparent calc(100% - var(--per, .25rem))
    ) 1;
    transition: --per .2s linear, background-color .15s linear;
    padding: 0 .5%;
    text-align: center;
    touch-action: none;
    word-break: keep-all;
    white-space: pre;
}
.li.windowMinimized{
    border-image: linear-gradient(to right,
        transparent var(--per, .25rem),
        var(--c-2) var(--per, .25rem),
        var(--c-2) calc(100% - var(--per, .25rem)),
        transparent calc(100% - var(--per, .25rem))
    ) 1;
}
.focus{
    --per: 0rem;
    background-color: var(--c-black-tra);
}
.li:hover{
    --per: 0rem;
    background-color: var(--c-black-tra-1);
}
.li:active{
    --per: 0rem;
    background-color: var(--c-black-tra-2);
}