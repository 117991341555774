body{
    margin: 0;
    padding: 0;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
}
body:is(.moving, .resizing), body:is(.moving, .resizing) *{
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    -webkit-user-drag: none !important;
    touch-action: none !important;
}
body.moving, body.moving *{
    cursor: grabbing !important;
}
body.resizing, body.resizing *{
    cursor: nwse-resize !important;
}
:is(body.moving, body.resizing) *[contenteditable]:not([contenteditable="false"]){
    -moz-user-modify: read-only !important;
    -webkit-user-modify: read-only !important;
}