.resizer{
    position: absolute;
    right: -.15rem;
    bottom: -.65rem;
    font-size: 1.5rem;
    color: var(--c-grey-4);
    cursor: nwse-resize;
}
.closing{
    cursor: not-allowed;
}