/* 为了不让Render函数看起来非常难看而创建的文件。
 * `Oinkedit` 仍是 @once 组件。*/
.tasks{
    /*它需要滚动，所以需要给Task实例一个offsetParent使其能在滚动时正确获得自己的坐标，因此需要设置position*/
    position: relative;
    color: var(--c-text-menu);
    transition: opacity .2s ease;
    min-width: 0;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
    height: 100%;
    flex: auto;
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    background-image:
        linear-gradient(to right, var(--c-taskbar), var(--c-taskbar) 35%, transparent),
        linear-gradient(to right, transparent 4%, var(--c-grey-4) 4%, var(--c-grey-5) 30%, transparent),
        linear-gradient(to left, var(--c-taskbar), var(--c-taskbar) 35%, transparent),
        linear-gradient(to left, transparent 4%, var(--c-grey-4) 4%, var(--c-grey-5) 30%, transparent);
    background-repeat: no-repeat;
    background-position: left, left, right, right;
    background-size: max(1rem, 6%) 100%, max(.3rem, 2.5%) 100%, max(1rem, 6%) 100%, max(.3rem, 2.5%) 100%;
    background-attachment: local, scroll, local, scroll;
}
.tasks::-webkit-scrollbar{
    width: .35rem;
    height: .35rem;
}
.right{
    justify-self: flex-end;
    text-align: right;
    white-space: nowrap;
}
.taskbar{
    display: flex;
    flex-flow: row nowrap;
    height: var(--cf-tbtaskbar);
    width: 99vw;
    box-shadow: 0 .125rem .8rem -0.125rem var(--c-black-tra);
    z-index: 9;
    gap: min(.5vw, .5rem);
    padding: 0 .5vw 0 .5vw;
    align-items: center;
    background-color: var(--c-taskbar);
    overflow: hidden;
}