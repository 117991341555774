.remirror-editor-wrapper{
    width: 100%;
    height: 100%;
}
.remirror-editor{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
    cursor: default;
}
.remirror-editor::after{
    content: "";
    width: 100%;
    height: 100%;
    cursor: text;
}
.remirror-editor *{
    cursor: text;
}
.remirror-is-empty:first-of-type{
    position: relative;
}
.remirror-is-empty:first-of-type::before{
    position: absolute;
    color: var(--c-grey-4);
    pointer-events: none;
    left: .0625rem;
    content: attr(data-placeholder);
}