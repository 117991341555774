.menu{
    font-size: 1rem;
    position: fixed;
    border-radius: .4rem;
    overflow: hidden;
    overflow: clip;
    display: inline-flex;
    flex-flow: row nowrap;
    background-color: var(--c-menu-2);
    padding: 0;
    box-shadow: #1010100C 0 0 0 .0625rem, 0 .25rem .9rem .2rem #00000033, 0 .25rem 1rem .75rem #00000008;
}
.main{
    content-visibility: auto;
}
.aLike{
    color: var(--c-link);
}
.menuButton{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    appearance: auto;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: .4rem .2rem;
    box-sizing: content-box;
    border: none;
    border-radius: 0;
    transition: background-color .2s ease;
    background-color: var(--c-6);
}
.menuButton:hover{
    background-color: var(--c-6-a);
}
.menuButton:active{
    transition: background-color .05s ease;
    background-color: var(--c-4);
}
.menuButton[data-disabled], .menuButton[data-disabled]:hover, .menuButton[data-disabled]:active{
    cursor: not-allowed;
    background-color: var(--c-6);
}
.menuButton *{
    height: 1.25rem;
    width: 1.25rem;
}
.menuButton[data-state="on"]{
    background-color: var(--c-4);
}