.settingUnitWrapper{
    --padding: .5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: calc(100% - 2 * var(--padding));
    min-height: 2.5rem;
    padding: .2rem var(--padding);
    margin: .125rem 0;
    justify-content: space-between;
    cursor: pointer;
    border-radius: .6rem;
    transition: background-color .1s ease;
}
.settingUnitWrapper:hover{
    background-color: var(--c-black-tra-1);
}
.settingUnitWrapper:active{
    transition: background-color .05s ease;
    background-color: var(--c-black-tra-3);
}
.switchRoot{
    min-width: 2.9rem;
    width: 2.9rem;
    height: 1.6rem;
    border-radius: 12914rem;
    background-color: var(--c-black-tra-2);
}
.switchRoot[data-state="checked"]{
    background-color: var(--c-focus);
}
.switchThumb{
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    background-color: var(--c-base);
    border-radius: 12914rem;
    box-shadow: 0 2px 2px var(--blackA7);
    transition: transform .1s;
    margin-left: .16rem;
    will-change: transform;
}
.switchThumb[data-state="checked"]{
    transform: translateX(1.3rem);
}